import { useDefaultCommandCenterControls } from "apollo/components/command-center/hooks";
import { useDetailedUsers } from "apollo/components/members/hooks/useDetailedUsers";
import { getMembershipStatus } from "apollo/components/members/utils/transformUsersForMemberTable";
import { EnumType } from "json-to-graphql-query";
import flatten from "lodash/flatten";
import React, { useMemo } from "react";
import { useBootcamps } from "ui/lib/useBootcamps";
import { createContainer } from "unstated-next";

// Utility function to check if a string is a valid email
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const useContainer = () => {
  const [search, setSearch] = React.useState("");
  const [teamID, setTeamID] = React.useState("");
  const { getBootcampById, bootcamps } = useBootcamps();

  const filter = useMemo(() => {
    const isEmail = isValidEmail(search);

    if (isEmail) {
      // If the search input is a valid email, filter by email
      return { email: { eq: search } };
    }

    const [$givenName = "", $familyName = ""] = search.split(" ");

    if (search.length === 36 && !$familyName) {
      // Allow searches for the user by ID
      return { id: { eq: search } };
    }

    const baseFilter = {
      and: [],
      or: [
        { givenName: { like: $givenName } },
        { familyName: { like: $familyName || $givenName } },
      ],
    };

    if (teamID) {
      // @ts-ignore
      baseFilter.and = [{ teamID: { eq: teamID } }];
    }

    if (!$familyName) {
      return baseFilter;
    }

    return {
      and: [
        { givenName: { like: $givenName } },
        { familyName: { like: $familyName || $givenName } },
        ...baseFilter.and,
      ],
    };
  }, [search, teamID]);

  const {
    status,
    data: response,
    error,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useDetailedUsers(
    {
      // @ts-ignore
      active: true,
      first: 50,
      filter,
      sort: [
        { by: new EnumType("lastActivityTS"), direction: -1 },
        // { by: new EnumType("numWorkoutsCompleted"), direction: -1 },
      ],
      node: {
        id: true,
        createdAt: {
          __aliasFor: "createdTS",
        },
        profileImg: {
          __aliasFor: "profileImgImgixURL",
        },
        teamID: true,
        givenName: true,
        familyName: true,
        email: true, // Ensure email is included in the query
        // membershipStatus: true,
        // lastActivityTS: true,
        // trialExpirationTS: true,
        // numWorkoutsCompleted: true,
      },
    },
    [filter]
  );

  const data = useMemo(() => {
    return flatten(
      response?.pages.map((data) =>
        // @ts-ignore
        (data?.detailedUsers?.edges || []).map((n) => ({
          ...n?.node,
          bootcampName: getBootcampById(n?.node?.teamID)?.name,
          fullName: `${n?.node?.givenName} ${n?.node?.familyName}`,
          cursor: n.cursor,
          membershipStatus: getMembershipStatus({
            membershipStatus: n?.node?.membershipStatus,
            trialExpirationTS: n?.node?.trialExpirationTS,
          }),
        }))
      )
    ).filter((n) => !!n.givenName);
  }, [response, getBootcampById]);

  const matchKeys = useMemo(() => {
    if (isValidEmail(search)) return ["email"];
    if (search.length === 36) return ["id"];
    return ["fullName"];
  }, [search]);

  const props = useDefaultCommandCenterControls({
    data,
    searchValue: search,
    setSearchValue: setSearch,
    matchKeys,
  });

  return {
    teamID,
    setTeamID,
    bootcamps,
    hasNextPage,
    fetchNextPage,
    isFetching,
    ...props,
  };
};

export const {
  useContainer: useCommandCenterMembersSearch,
  Provider: CommandCenterMembersSearchProvider,
} = createContainer(useContainer);
