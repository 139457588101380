import { DateSelector, THEME } from "apollo/components/date-selector";
import React, { JSX } from "react";
import { Spinner } from "ui/components/icons/Spinner";
import { getValidDate } from "ui/lib/helpers";
import { DisplayMember } from ".";
import { Header } from "..";
import {
  CommandCenterMembersDateProvider,
  useCommandCenterMembersDate,
} from "../containers/members/date";

const HeaderInContext = () => {
  const { isSaving } = useCommandCenterMembersDate();
  return (
    <div id="command-center-header" className="relative pb-4 shadow">
      <Header id="memberId">
        {(props) => <DisplayMember key={props.key} memberId={props.value} />}
      </Header>
      {isSaving ? (
        <div className="absolute top-6 right-4">
          <Spinner className="w-[20px] h-[20px] text-white" />
        </div>
      ) : null}
    </div>
  );
};

const Content = React.memo(() => {
  const { value, updateDate } = useCommandCenterMembersDate();
  return (
    <div className="relative px-8 pb-12 pt-10 border-t border-neutral-600 flex-center bg-background">
      <div style={{ transform: "scale(1.1)" }}>
        <DateSelector
          theme={THEME.dark}
          currentDate={getValidDate(value)}
          // @ts-ignore
          handleSelect={updateDate}
          defaultEventTooltipText=""
        />
      </div>
    </div>
  );
});

Content.displayName = "DateContent";

export const MemberDateCommandCenterContext = React.memo((): JSX.Element => {
  return (
    <CommandCenterMembersDateProvider
      initialState={{ columnId: "trialExpiration" }}
    >
      <HeaderInContext />
      <Content />
    </CommandCenterMembersDateProvider>
  );
});

MemberDateCommandCenterContext.displayName = "MemberDateCommandCenterContext";
