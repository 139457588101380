import * as Checkbox from "@radix-ui/react-checkbox";
import {
  CheckCircledIcon,
  CheckIcon,
  CopyIcon,
  TrashIcon,
  UploadIcon,
} from "@radix-ui/react-icons";
import MediaModal from "apollo/components/media-modal";
import { VideoPlayer } from "apollo/components/media-modal/video/player";
import { VideoUploader } from "apollo/components/media-modal/video/uploader";
import { DropdownMultipleCombobox } from "apollo/components/multiselect-combobox";
import { Select } from "apollo/components/select";
import { format } from "date-fns";
import { AnimatePresence, LayoutGroup, motion } from "motion/react";
import { useEquipment } from "queries/equipment/hooks";
import { useTeams } from "queries/teams";
import React, { useState } from "react";
import useCopyClipboard from "react-use-clipboard";
import { getValidDate } from "ui/lib/helpers";
import { cn } from "ui/lib/utils";

export const ImageCard = React.memo(({ src, ...props }) => {
  const [expanded, setExpanded] = useState(false);

  if (!src) return null;

  return (
    <div>
      <LayoutGroup>
        <motion.img
          src={src}
          layoutId={src}
          initial={false}
          onClick={() => setExpanded(true)}
          {...props}
        />
        <AnimatePresence mode="wait">
          {expanded ? (
            <motion.div
              onClick={() => setExpanded(false)}
              style={{ backgroundColor: "rgb(0 0 0 / 25%)", zIndex: 999 }}
              className="fixed inset-0 z-50 flex-center cursor-zoom-out overscroll-none"
            >
              <motion.img
                layoutId={src}
                onClick={() => setExpanded(false)}
                style={{
                  maxHeight: `calc(100vh - 200px)`,
                }}
                className="max-w-5xl border border-neutral-500 max-h-screen m-auto shadow-xl rounded-xl cursor-zoom-out"
                src={src}
              />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </LayoutGroup>
    </div>
  );
});

export const SectionDetails = ({ section }) => {
  return (
    <div className="space-y-0.5 mb-8">
      <h2 className="text-2xl font-bold tracking-tight">{section.name}</h2>
      <p className="text-muted-foreground">{section.description}</p>
    </div>
  );
};

export const WithLabel = ({ label, description, htmlFor, children }) => {
  return (
    <div className="grid items-start max-w-2xl grid-cols-3 gap-4 pb-5 border-b border-white/10">
      <label
        htmlFor={htmlFor}
        className="block pt-2 mt-px text-sm font-medium text-muted-foreground"
      >
        {label}
        {description ? (
          <span className="block text-xs opacity-75">{description}</span>
        ) : null}
      </label>
      <div className="relative col-span-2 mt-0">{children}</div>
    </div>
  );
};

const Upload = ({
  onChange,
  onUploadVideo,
  onUploadImage,
  id,
  type,
  value,
  disabled,
  ...props
}) => {
  const [showUploader, setShowUploader] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const onDrop = async (props) => {
    try {
      setUploadLoading(true);
      if (type === "video") {
        await onUploadVideo(props);
      }
      if (type === "image") {
        await onUploadImage(props);
      }

      setShowUploader(false);
      setUploadLoading(false);
    } catch (error) {
      console.error(error);
      // setError(error);
      // Sentry.captureException(error);
      // toast.error("Something went wrong");
    }
  };

  return (
    <>
      {showUploader ? (
        <MediaModal
          isOpen={true}
          height={"auto"}
          deactivateMediaModal={() => setShowUploader(false)}
        >
          <VideoUploader
            uploadId={id}
            onDrop={onDrop}
            loading={uploadLoading}
            onClose={() => setShowUploader(false)}
            {...props}
          />
        </MediaModal>
      ) : null}
      <div className="relative flex items-center justify-center space-x-2 p-2">
        {value ? (
          <button
            disabled={disabled}
            className="p-2 border rounded-full bg-background shadow-button"
            onClick={() => onChange()}
          >
            <TrashIcon className="w-5 h-5 text-error" />
          </button>
        ) : null}
        <button
          id={"file-upload-" + id}
          disabled={disabled}
          onClick={() => setShowUploader(true)}
          className="w-full h-full px-4 flex-center space-x-2 py-1 text-black bg-neutral-200 rounded-full shadow-button"
        >
          <UploadIcon className="w-4 h-4" />
          <span className="capitalize">
            {value ? "Replace" : "Upload"} {type}
          </span>
        </button>
      </div>
    </>
  );
};

const CopyComponent = ({ field, value }) => {
  const [isCopied, setCopied] = useCopyClipboard(value, {
    successDuration: 2500,
  });

  return (
    <div className="relative">
      <button
        onClick={setCopied}
        className="flex space-x-2 items-center py-2 w-full min-w-0 text-muted-foreground rounded-md sm:text-sm px-1 focus:ring-0 focus:outline-none cursor-copy"
      >
        <span>{value}</span>
        {isCopied ? (
          <CheckCircledIcon className="w-4 " />
        ) : (
          <CopyIcon className="w-4 " />
        )}
      </button>
    </div>
  );
};

export const TeamMultiSelect = ({
  field,
  value = [],
  onComboBoxChange,
  id,
}) => {
  const { teams } = useTeams();
  if (!teams?.length) return null;
  return (
    <DropdownMultipleCombobox
      items={teams?.map(({ id, name }) => ({
        label: name,
        value: id,
      }))}
      selectedItemValues={value}
      selectedItems={value
        ?.map?.(
          (n) =>
            n && {
              label: n,
              value: n,
            }
        )
        .filter(Boolean)}
      handleSelectedItemsChange={onComboBoxChange}
      keepOpenOnSelection={false}
      maxVisble={4}
    />
  );
};

const EquipmentMultiEditor = ({ field, value = [], onComboBoxChange, id }) => {
  const { data } = useEquipment();
  if (!data?.length) return null;
  return (
    <DropdownMultipleCombobox
      items={data?.map(({ id, name }) => ({
        label: name,
        value: id,
      }))}
      selectedItemValues={value}
      selectedItems={value
        ?.map?.(
          (n) =>
            n && {
              label: n,
              value: n,
            }
        )
        .filter(Boolean)}
      handleSelectedItemsChange={onComboBoxChange}
      keepOpenOnSelection={false}
      maxVisble={4}
    />
  );
};

const EquipmentEditor = ({ field, value = "", onChange, id }) => {
  const { data } = useEquipment();
  if (!data?.length) return null;
  const options = data?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  return (
    <Select
      value={value}
      disabled={!data}
      onChange={onChange}
      options={options}
      className={cn("pl-2 pr-8 border py-1", {
        "text-neutral-500": !value,
        "text-muted-foreground": value,
      })}
    />
  );
};

export const Field = ({
  field,
  value = [],
  onChange,
  onComboBoxChange,
  onUploadVideo,
  onUploadImage,
  id,
}) => {
  const disabled = "boolean" === typeof field.edit && !field.edit;
  switch (field.type) {
    case "combobox":
      return (
        <DropdownMultipleCombobox
          items={field.items || []}
          selectedItemValues={value}
          selectedItems={value
            ?.map(
              (n) =>
                n && {
                  label: n,
                  value: n,
                }
            )
            .filter(Boolean)}
          handleSelectedItemsChange={onComboBoxChange}
          maxVisble={5}
          keepOpenOnSelection={false}
        />
      );
    case "teamIDs":
      return (
        <TeamMultiSelect
          {...{ field, value, onChange, onComboBoxChange, id }}
        />
      );
    case "equipmentMulti":
      return (
        <EquipmentMultiEditor
          {...{ field, value, onChange, onComboBoxChange, id }}
        />
      );
    case "equipment":
      return (
        <EquipmentEditor
          {...{ field, value, onChange, onComboBoxChange, id }}
        />
      );
    case "title":
      return <h2 className="text-xl font-display">{value}</h2>;
    case "text":
      return (
        <div>
          <input
            type="text"
            disabled={disabled}
            name={field.accessor}
            id={field.accessor}
            value={value || ""}
            onChange={onChange}
            className="flex-1 block w-full min-w-0 text-foreground rounded-md bg-background/20 border-white/10 focus:ring-primary focus:border-primary sm:text-sm"
          />
          {field.subText ? (
            <span className="text-sm text-foreground/50">{field.subText}</span>
          ) : null}
        </div>
      );
    case "copy":
      return <CopyComponent value={value} field={field} />;
    case "textarea":
      return (
        <textarea
          rows={3}
          disabled={disabled}
          id={field.accessor}
          name={field.accessor}
          onChange={onChange}
          className="block w-full max-w-lg text-foreground border rounded-md shadow-sm bg-background/20 border-white/10 focus:ring-primary focus:border-primary sm:text-sm"
          value={value || ""}
        />
      );
    case "select":
      return (
        <Select
          value={value || ""}
          disabled={disabled}
          onChange={onChange}
          options={field.options}
          className={cn("pl-2 pr-8 border py-1", {
            "text-muted-foreground": !field.value,
            "text-neutral-500": field.value,
          })}
        />
      );
    case "color":
      return (
        <div className="flex items-center space-x-2 text-foreground bg-background rounded-md border-white/10 sm:text-sm">
          <input
            type="color"
            disabled={disabled}
            name={field.accessor}
            id={field.accessor}
            value={`#${value}` || ""}
            onChange={(e) =>
              onChange({ target: { value: e.target.value.substring(1) } })
            }
            className="flex-1 block w-full min-w-0 bg-transparent"
          />
          <span>{value}</span>
        </div>
      );
    case "timestamp":
      return (
        <div>
          <input
            type="date"
            className="flex-1 block w-full min-w-0 text-foreground rounded-md bg-background/20 border-white/10 focus:ring-primary focus:border-primary sm:text-sm"
            name={field.accessor}
            id={field.accessor}
            value={value && format(getValidDate(value), "yyy-MM-dd")}
            disabled={disabled}
          />
        </div>
      );
    case "boolean":
      return (
        <div className="flex space-x-2 rounded-md text-whit sm:text-sm">
          <Checkbox.Root
            checked={value}
            disabled={disabled}
            id={field.accessor}
            onCheckedChange={(value) => onChange({ target: { value } })}
            className={cn(
              "flex h-5 w-5 items-center justify-center rounded",
              "radix-state-checked:bg-primary radix-state-unchecked:bg-background/25 border border-neutral-500",
              "focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75"
            )}
          >
            <Checkbox.Indicator>
              <CheckIcon className="self-center w-4 h-4 text-black" />
            </Checkbox.Indicator>
          </Checkbox.Root>
        </div>
      );
    case "radio":
      return (
        <div className="mt-4 space-y-4">
          {field.options.map(({ value, label }) => (
            <div key={value} className="flex items-center">
              <input
                id={value}
                disabled={disabled}
                name="push-notifications"
                type="radio"
                className="w-4 h-4 text-royal border-white/10"
              />
              <label
                htmlFor={value}
                className="block ml-3 text-sm font-medium text-muted-foreground"
              >
                {label}
              </label>
            </div>
          ))}
        </div>
      );
    case "image":
      return value ? (
        <div className="relative max-w-md flex-center group">
          <div className="bg-card border w-full flex rounded-xl">
            <ImageCard
              className="min-w-[6rem] h-[6rem] rounded-xl cursor-zoom-in object-cover"
              src={value}
            />
            <div className="w-full flex px-4 items-center justify-end">
              <Upload
                value={value}
                onChange={onChange}
                disabled={disabled}
                id={`${id}-${field.accessor}`}
                type="image"
                accept={["png", "jpg", "jpeg"]}
                onUploadImage={onUploadImage}
              />
            </div>
          </div>
        </div>
      ) : (
        <Upload
          onChange={onChange}
          disabled={disabled}
          id={`${id}-${field.accessor}`}
          type="image"
          accept={["png", "jpg", "jpeg"]}
          onUploadImage={onUploadImage}
        />
      );
    case "video":
      return value ? (
        <div className="relative max-w-md flex-center group">
          {!field.disabledActions ? (
            <div className="absolute top-0 right-0 z-[1] flex p-2 mt-8 opacity-0 group-hover:opacity-100">
              <Upload
                value={value}
                onChange={onChange}
                id={`${id}-${field.accessor}`}
                type="video"
                onUploadVideo={onUploadVideo}
                disabled={disabled}
              />
            </div>
          ) : null}
          <VideoPlayer url={value} src={value} controls autoPlay={false} loop />
        </div>
      ) : !field.disabledActions ? (
        <div className="h-full center">
          <Upload
            onChange={onChange}
            id={`${id}-${field.accessor}`}
            disabled={disabled}
            type="video"
            onUploadVideo={onUploadVideo}
          />
        </div>
      ) : null;
    default:
      return "unknown field";
  }
};
