import { JSX } from "react";
import Highlighter from "react-highlight-words";
import { fn } from "ui/lib/helpers";
import { cn } from "ui/lib/utils";

type option = {
  value: string | number;
  label: string;
  subtitle: string;
  disabled?: (prop: any) => boolean;
};

type props = {
  textToHighlight: string;
  size: number;
  start: number;
  searchWords: string;
  highlighted: boolean;
  renderIcon?: () => any;
  handleClick: (e: any) => any;
  onMouseEnter: (e: any) => any;
  option: option;
  data?: any;
  iconPosition?: "left" | "right";
};

export const LineItem = ({
  textToHighlight,
  size,
  start,
  searchWords,
  highlighted,
  renderIcon = fn,
  handleClick = fn,
  onMouseEnter = fn,
  option = { value: "", label: "", subtitle: "", disabled: () => false },
  data,
  iconPosition = "left",
}: props): JSX.Element => {
  return (
    <button
      className={cn({
        "py-2 px-4 text-sm cursor-pointer flex-center justify-start group w-full focus:outline-none focus:ring-0":
          true,
        "bg-secondary": highlighted,
      })}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
      role="button"
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      disabled={option?.disabled?.(data)}
    >
      <div className="flex items-center w-full space-x-4">
        {iconPosition === "left" ? renderIcon() : null}
        <div className="flex flex-col items-start w-full">
          <Highlighter
            autoEscape
            searchWords={[searchWords || ""]}
            textToHighlight={textToHighlight}
            highlightClassName="bg-yellow-300"
          />
          {option.subtitle ? (
            <span className="text-xs opacity-75">{option.subtitle}</span>
          ) : null}
        </div>
        {iconPosition === "right" ? renderIcon() : null}
      </div>
    </button>
  );
};
