// file-uploader.ts
import * as Sentry from "@sentry/nextjs";
import { useEffect, useRef, useState } from "react";
import { useS3Upload } from "ui/lib/useS3Upload";
import { createContainer } from "unstated-next";

interface CurrentUpload {
  id: string;
  newUploadData: any;
  clearUploadOnFinish: boolean;
  percentage: number;
  completed?: boolean;
  failed?: boolean;
}

type UploadsState = {
  [key: string]: CurrentUpload;
};

interface NewUploadData {
  id: string;
  file: File;
  bucket: string; // Allow specifying the bucket
  prefix?: string;
  clearUploadOnFinish?: boolean;
}

const isHeic = (file: File) => {
  return (
    file.type === "image/heic" || file.name.toLowerCase().endsWith(".heic")
  );
};

const useContainer = () => {
  const { upload } = useS3Upload();
  const [currentUploads, setCurrentUploads] = useState<UploadsState>({});
  const clearUploadFromState = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    return () => {
      if (clearUploadFromState.current) {
        clearTimeout(clearUploadFromState.current);
      }
    };
  }, []);

  const getProgress = (progress: { loaded: number; total: number }): number => {
    return Math.floor((progress.loaded * 100) / progress.total);
  };

  const handleClearUploadFromState = (id: string) => {
    if (!id) return;
    clearUploadFromState.current = setTimeout(() => {
      setCurrentUploads((current) => {
        const { [id]: _, ...uploads } = current;
        return uploads;
      });
    }, 5000);
  };

  const handleUploadFinished = (id: string) => {
    setCurrentUploads((current) => ({
      ...current,
      [id]: { ...current[id], percentage: 100, completed: true },
    }));
  };

  const handleUploadProgress =
    (id: string) => (progress: { loaded: number; total: number }) => {
      const percentage = getProgress(progress);
      setCurrentUploads((current) => ({
        ...current,
        [id]: { ...current[id], percentage },
      }));
    };

  const handleNewUpload = async (
    data: NewUploadData
  ): Promise<{ key: string; url: string }> => {
    if (!data.id) {
      throw new Error("id is required");
    }

    if (data.file && isHeic(data.file)) {
      throw new Error(
        "HEIC files are not supported. Please convert to JPEG or PNG."
      );
    }

    try {
      setCurrentUploads((current) => ({
        ...current,
        [data.id]: {
          id: data.id,
          newUploadData: data,
          clearUploadOnFinish: data.clearUploadOnFinish || false,
          percentage: 0,
        },
      }));

      const uploadResponse = await upload(
        {
          file: data.file,
          prefix: data.prefix,
          bucket: data.bucket,
        },
        handleUploadProgress(data.id)
      );

      if (!uploadResponse) throw new Error("upload failed");
      handleUploadFinished(data.id);

      if (data.clearUploadOnFinish) {
        handleClearUploadFromState(data.id);
      }

      return uploadResponse;
    } catch (error) {
      handleClearUploadFromState(data.id);
      Sentry.captureException(error);
      throw error;
    }
  };

  return {
    currentUploads,
    setCurrentUploads,
    handleNewUpload,
    handleClearUploadFromState,
    handleUploadFinished,
    handleUploadProgress,
  };
};

// we use unstated-next as a react context alternative
export const { useContainer: useFileUploader, Provider: FileUploaderProvider } =
  createContainer(useContainer);
