import { motion } from "motion/react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { createContainer } from "unstated-next";

export const toastTypes = {
  COPIED_SECTION: "COPIED_SECTION",
};

const useContainer = () => {
  const [toastId, setToastId] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [data, setData] = useState({});

  const handleCancel = () => {
    toast.dismiss(toastId);
    setToastId("");
    setMessage("");
    setType("");
  };

  const handleShowToast = ({ message, data, type }) => {
    const id = toast.custom(
      (t) => (
        <motion.div
          initial={{ y: 100, opacity: 0, scale: 0.5 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: 100, opacity: 0, scale: 0.5 }}
          className={`max-w-sm w-full bg-secondary shadow-lg rounded pointer-events-auto flex border border-neutral-700`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="ml-3 flex-1">
                <p className="text-foreground">{message}</p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-neutral-700">
            <button
              onClick={handleCancel}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-muted-foreground"
            >
              Cancel
            </button>
          </div>
        </motion.div>
      ),
      {
        duration: Infinity,
        position: "bottom-right",
      }
    );
    setToastId(id);
    setMessage(message);
    setData(data);
    setType(type);
  };

  return {
    toastActive: !!toastId,
    handleCancel,
    handleShowToast,
    message,
    type,
    data,
  };
};

export const {
  useContainer: useCancelableToast,
  Provider: CancelableToastProvider,
} = createContainer(useContainer);
