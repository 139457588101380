import * as DialogPrimitive from "@radix-ui/react-dialog";
import { motion } from "motion/react";
import React from "react";
import { cn } from "ui/lib/utils";

export const MediaModalShell = ({
  children,
  isOpen,
  handleClose,
  height = 780,
  width = 420,
  showOverlay = true,
  className = "",
}: {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  showOverlay?: boolean;
  handleClose: () => void;
  height?: string | number;
  width?: string | number;
}) => {
  return (
    <DialogPrimitive.Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogPrimitive.Portal>
        {showOverlay ? (
          <DialogPrimitive.Overlay className="fixed inset-0 z-50 bg-background/60 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        ) : null}
        <DialogPrimitive.Content>
          <motion.div
            style={{
              maxHeight: "96vh",
              minWidth: 420,
              width,
              height,
            }}
            className={cn(
              "fixed overflow-visible border bg-card rounded-xl left-[50%] top-[50%] z-50 grid w-full h-full max-w-[90%] max-h-[90%] translate-x-[-50%] translate-y-[-50%] gap-4 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] md:w-full",
              className
            )}
          >
            {children}
          </motion.div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Dialog>
  );
};
