import { MicrophoneIcon, VideoCameraIcon } from "@heroicons/react/solid";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { CheckIcon } from "@radix-ui/react-icons";
import { Timer } from "apollo/components/audio-recorder/timer";
import { AnimatePresence, motion } from "motion/react";
import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import ArrowRight from "ui/components/icons/ArrowRight";
import { Spinner } from "ui/components/icons/Spinner";
import { TrashIcon } from "ui/components/icons/TrashIcon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "ui/components/primitives/dropdown-menu";
import { useSpacebarPress } from "ui/hooks/useSpacebarPress";
import { fn } from "ui/lib/helpers";
import { cn } from "ui/lib/utils";

const useVideoRecorder = () => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedMicrophone, setSelectedMicrophone] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [cameras, setCameras] = useState(null);
  const [microphones, setMicrophones] = useState(null);
  const [status, setStatus] = useState("idle");
  const [mediaBlob, setMediaBlob] = useState(null);
  const [permissionError, setPermissionError] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleWebcamLoaded = () => {
    setLoading(false);
  };

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      const microphones = devices.filter(
        (device) => device.kind === "audioinput"
      );

      return {
        cameras,
        microphones,
        devices,
      };
    } catch (err) {
      console.error("Error fetching devices:", err);
    }
  };

  React.useEffect(() => {
    const requestPermissions = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        const { cameras, microphones } = await getDevices();
        setPermissionError(false);
        setSelectedCamera(cameras[0]?.deviceId || null);
        setSelectedMicrophone(microphones[0]?.deviceId || null);
        setCameras(cameras);
        setMicrophones(microphones);
      } catch (err) {
        setPermissionError(true);
      }
    };

    requestPermissions();
  }, []);

  const startRecording = async () => {
    setStatus("countdown");
    let count = 3;
    setCountdown(count);

    const countdownInterval = setInterval(async () => {
      count--;
      setCountdown(count);
      if (count === 0) {
        clearInterval(countdownInterval);
        setCountdown(null);
        setStatus("recording");
        const options = { mimeType: "video/webm;codecs=vp9,opus" };
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: selectedMicrophone },
          video: { deviceId: selectedCamera, aspectRatio: 9 / 16 },
        });
        mediaRecorderRef.current = new MediaRecorder(stream, options);

        mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
          if (event.data.size > 0) {
            setMediaBlob(event.data);
          }
        });

        mediaRecorderRef.current.start();
      }
    }, 1000);
  };

  const stopRecording = () => {
    setStatus("ready");
    mediaRecorderRef.current.stop();
  };

  const updateStream = async () => {
    // Stop the current stream if it exists
    if (webcamRef.current.stream) {
      webcamRef.current.stream.getTracks().forEach((track) => track.stop());
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: selectedMicrophone },
      video: { deviceId: selectedCamera },
    });

    // Set the new stream to the webcam ref
    webcamRef.current.stream = stream;
  };

  const clearMediaBlob = () => {
    setMediaBlob(null);
  };

  return {
    isRecording: status === "recording",
    status,
    mediaBlob,
    loading,
    updateStream,
    handleWebcamLoaded,
    startRecording,
    stopRecording,
    clearMediaBlob,
    getDevices,
    cameras,
    microphones,
    selectedCamera,
    setSelectedCamera,
    selectedMicrophone,
    setSelectedMicrophone,
    permissionError,
    webcamRef,
    countdown,
  };
};

/**
 * @typedef PlayerProps
 * @type {object}
 * @property {Blob} srcBlob
 *
 * @param {PlayerProps}
 */
function Player({ srcBlob, ...props }) {
  if (!srcBlob) return null;

  return (
    <video src={URL.createObjectURL(srcBlob)} controls autoPlay {...props} />
  );
}

const VideoRecorderContent = ({ handleSave }) => {
  const {
    isRecording,
    loading,
    handleWebcamLoaded,
    mediaBlob,
    updateStream,
    startRecording,
    stopRecording,
    clearMediaBlob,
    setSelectedCamera,
    setSelectedMicrophone,
    cameras,
    microphones,
    selectedCamera,
    selectedMicrophone,
    permissionError,
    webcamRef,
    countdown,
    status,
  } = useVideoRecorder();

  const handleClick = async () => {
    if (mediaBlob) return;
    if (!isRecording) {
      clearMediaBlob();
      startRecording();
    } else {
      stopRecording();
    }
  };

  useSpacebarPress({
    onKeyPress: handleClick,
  });

  const handleDiscard = async () => {
    clearMediaBlob();
  };

  const onHandleSave = async () => {
    const file = new File([mediaBlob], "video-recording.mp4", {
      type: "video/mp4",
      lastModified: Date.now(),
    });
    handleSave(file);
  };

  return (
    <div className="relative h-full w-full overflow-hidden">
      <section className="absolute inset-x-0 top-0 z-20 w-full p-6 flex-center">
        <div
          className={cn("text-white px-2 py-1", {
            "bg-black/50": isRecording,
            "opacity-0": !isRecording,
          })}
        >
          {isRecording ? (
            <Timer key={isRecording} isActive={isRecording} />
          ) : (
            "0:00"
          )}
        </div>
      </section>
      <div className="h-full w-full max-w-7xl mx-auto flex-center">
        {countdown !== null && (
          <div className="absolute inset-0 flex-center text-8xl text-white">
            {countdown}
          </div>
        )}
        {permissionError && (
          <div className="flex-col px-12 space-y-4 flex-center absolute h-full w-full p-4 bg-black/20">
            <div className="text-sm text-center text-foreground">
              <span>
                Please ensure Apollo has access to your camera and microphone
              </span>
            </div>
          </div>
        )}
        {loading && (
          <div className="absolute inset-0 flex-center">
            <Spinner className="w-[30px] h-[30px] text-white" />
          </div>
        )}
        {!mediaBlob ? (
          <Webcam
            audio
            muted
            className="w-full max-w-xl h-auto rounded bg-black"
            onUserMedia={handleWebcamLoaded}
            videoConstraints={{
              deviceId: selectedCamera,
              aspectRatio: 9 / 16,
            }}
            audioConstraints={{
              deviceId: selectedMicrophone,
            }}
            ref={webcamRef}
          />
        ) : null}
        <Player
          className="w-full max-w-xl h-auto rounded bg-black"
          srcBlob={mediaBlob}
        />
      </div>
      <section className={cn("z-20 absolute w-full bottom-0 p-2")}>
        <AnimatePresence mode="popLayout">
          {!mediaBlob ? (
            <motion.div
              key="recording-controls"
              initial={{ opacity: 0, scale: 0.9, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.9, y: 10 }}
              className="flex-center gap-4"
            >
              {!isRecording ? (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button className="rounded-full bg-secondary border p-2">
                      <MicrophoneIcon className="w-6" />
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent side="top">
                    {microphones?.map((microphone) => (
                      <DropdownMenuItem
                        onClick={(e) => {
                          setSelectedMicrophone(microphone.deviceId);
                        }}
                        key={microphone.deviceId}
                        value={microphone.deviceId}
                      >
                        <span>{microphone.label}</span>
                        {selectedMicrophone === microphone.deviceId ? (
                          <CheckIcon className="w-5 h-5 text-primary" />
                        ) : null}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : null}
              <button
                autoFocus
                onClick={handleClick}
                disabled={status === "countdown"}
                className={cn(
                  "border-4 border-white rounded-full w-16 h-16 flex-center ring-0 focus:ring-0 focus:outline-none transition duration-500 ease-in-out"
                )}
              >
                <div
                  className={cn(
                    "bg-red-500 transition duration-500 ease-in-out block",
                    {
                      "rounded-full w-12 h-12": !isRecording,
                      "rounded w-8 h-8 scale-90": isRecording,
                      "scale-100": status !== "countdown",
                      "scale-75": status === "countdown",
                    }
                  )}
                />
              </button>
              {!isRecording ? (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button className="rounded-full bg-secondary border p-2">
                      <VideoCameraIcon className="w-6" />
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent side="top">
                    {cameras?.map((camera) => (
                      <DropdownMenuItem
                        onClick={(e) => {
                          setSelectedCamera(camera.deviceId);
                        }}
                        key={camera.deviceId}
                        value={camera.deviceId}
                      >
                        <span>{camera.label}</span>
                        {selectedCamera === camera.deviceId ? (
                          <CheckIcon className="w-5 h-5 text-primary" />
                        ) : null}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : null}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </section>
      <section className={cn("z-20 absolute w-full top-0 p-2")}>
        <AnimatePresence mode="popLayout">
          {!!mediaBlob ? (
            <motion.div
              key="save-controls"
              initial={{ opacity: 0, scale: 0.9, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.9, y: 10 }}
              className="w-full space-x-4 flex items-center justify-between"
            >
              <button
                className="rounded-full bg-secondary border p-2"
                onClick={handleDiscard}
              >
                <TrashIcon className="w-5 text-error" />
              </button>
              <button
                className={cn(
                  "bg-white shadow-md text-black font-bold rounded-full px-4 py-2 flex-center gap-2 group"
                )}
                onClick={onHandleSave}
              >
                <span className="">Next</span>
                <ArrowRight className="w-5 transition-transform group-hover:translate-x-1" />
              </button>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </section>
    </div>
  );
};

export const VideoRecorder = React.memo(
  ({ handleSave = fn, open = false, onOpenChange = fn }) => {
    return (
      <DialogPrimitive.Dialog open={open} onOpenChange={onOpenChange}>
        <DialogPrimitive.Portal>
          <DialogPrimitive.Overlay className="fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
          <DialogPrimitive.Content
            style={{
              maxHeight: 730,
              minHeight: 730,
              width: 420,
              minWidth: 420,
            }}
            className={
              "fixed overflow-scroll left-[50%] top-[50%] z-50 grid w-full h-full rounded-xl max-w-[90%] max-h-[90%] translate-x-[-50%] translate-y-[-50%] gap-4 border bg-popover p-2 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] md:w-full"
            }
          >
            <VideoRecorderContent handleSave={handleSave} />
            {/* <DialogPrimitive.Close className="absolute z-30 right-4 top-4 opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </DialogPrimitive.Close> */}
          </DialogPrimitive.Content>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Dialog>
    );
  }
);
