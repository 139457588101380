import React, { JSX } from "react";
import Highlighter from "react-highlight-words";
import { Cross } from "ui/components/icons";
import CheckMark from "ui/components/icons/CheckMark";
import { Spinner } from "ui/components/icons/Spinner";
import { cn } from "ui/lib/utils";
import {
  CommandCenterMembersTagProvider,
  useCommandCenterMembersTag,
} from "../containers/members/tags";
import { Header } from "../index";
import { DisplayMember } from "./index";

const Input = React.memo(() => {
  const {
    getDropdownProps,
    getInputProps,
    disclosureRef,
    getComboboxProps,
    selectedItemValues,
    removeSelectedItem,
    isSaving,
  } = useCommandCenterMembersTag();

  return (
    <div {...getComboboxProps()} className="border-b border-neutral-600">
      <div className="relative">
        <input
          autoFocus
          className="w-full p-4 text-xl bg-transparent focus:ring-0"
          placeholder="Add member tags..."
          {...getInputProps(
            getDropdownProps({
              ref: disclosureRef,
            })
          )}
        />
        <div className="absolute inset-y-0 right-4">
          {isSaving ? (
            <Spinner className="w-[20px] h-[20px] text-white" />
          ) : null}
        </div>
      </div>
      <div className="flex flex-wrap mx-4">
        {selectedItemValues?.length ? (
          selectedItemValues.map((value, key) => (
            <div className="mb-1 mr-2" key={key}>
              <button
                onClick={() => removeSelectedItem({ value })}
                className="relative px-2 pr-1 mb-2 text-xs rounded-full bg-royal shadow-button flex-center group"
              >
                <span>{value}</span>
                <span className="p-1 rounded-full -right-0 flex-center group-hover:flex">
                  <Cross className="w-3 h-3 text-foreground" />
                </span>
              </button>
            </div>
          ))
        ) : (
          <div className="w-full py-3" />
        )}
      </div>
    </div>
  );
});

Input.displayName = "Input";

const Content = React.memo(() => {
  const {
    isCreating,
    selectedItemValues,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    inputItems,
  } = useCommandCenterMembersTag();

  return (
    <div
      {...getMenuProps({
        style: { overflow: "hidden auto" },
        className: "w-full",
      })}
    >
      <ul className="shadow-md">
        {inputItems?.length &&
          inputItems.map((item, index) => (
            <li
              className={cn({
                "py-2 px-4 text-sm cursor-pointer flex-center justify-start group":
                  true,
                "bg-secondary": highlightedIndex === index,
              })}
              style={{ height: 46 }}
              key={`${item.value}${index}`}
              role="button"
              {...getItemProps({ item, index })}
            >
              {isCreating ? (
                <p>
                  <span>Create</span>{" "}
                  <span className="font-medium text-yellow-900 bg-yellow-300">
                    {item.label}
                  </span>
                </p>
              ) : (
                <div className="flex items-center space-x-4">
                  {selectedItemValues.includes(item.value) ? (
                    <div className="w-5 h-5 rounded bg-royal group-focus:bg-royal group-hover:bg-secondary shadow-button">
                      <CheckMark className="text-sm" />
                    </div>
                  ) : (
                    <div className="w-5 h-5 rounded bg-neutral-600 group-hover:bg-secondary shadow-button" />
                  )}
                  <Highlighter
                    autoEscape
                    searchWords={[inputValue || ""]}
                    textToHighlight={item.label}
                    highlightClassName="bg-yellow-300"
                  />
                </div>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
});

Content.displayName = "TagsContent";

export const MemberTagsCommandCenterContext = React.memo((): JSX.Element => {
  return (
    <CommandCenterMembersTagProvider>
      <div id="command-center-header" className="shadow">
        <Header id="memberId">
          {(props) => <DisplayMember key={props.key} memberId={props.value} />}
        </Header>
        <Input />
      </div>
      <Content />
    </CommandCenterMembersTagProvider>
  );
});

MemberTagsCommandCenterContext.displayName = "MemberTagsCommandCenterContext";
